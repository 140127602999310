import React, { useRef, useState } from 'react'
import logo from "../assest/log.png"
import aaa from '../assest/ioann-mark-kuznietsov-F_cHIM0Kcy4-unsplash.png';
import bbb from '../assest/patrick-fore-b_SHPU5M3nk-unsplash.png';
import ccc from '../assest/robin-worrall-FPt10LXK0cg-unsplash.png';
import ddd from '../assest/andrik-langfield-0rTCXZM7Xfo-unsplash.png';
import girl from '../assest/Rectangle 6475.png';
import blackMan from '../assest/Rectangle 6477.png';
import girl2 from '../assest/Rectangle 6480.png';
import { HiOutlineMail } from "react-icons/hi";
import './Landing.css';
import { Link } from "react-scroll";
import './responsive.css';
import { Col, Row, Button, Rate, Carousel, Card, Drawer, FloatButton, Modal, QRCode } from 'antd';
import { DebounceInput } from 'react-debounce-input';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import prevIcon from "../assest/prev.png"
import nextIcon from "../assest/next.png"
import appStoreLogo from "../assest/apple.png"
import playStoreLogo from "../assest/playStore.png"
const myBanner = [
    {
        id: 0,
        image: girl
    },
    {
        id: 1,
        image: blackMan
    },
    {
        id: 2,
        image: girl2
    },
    {
        id: 3,
        image: girl
    },
    {
        id: 4,
        image: blackMan
    },
    {
        id: 5,
        image: girl2
    },

]
const Landing = () => {
    const [state, setState] = useState()
    const [openModal, setOpenModal] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const slider = useRef(null);
    const appStoreUrl = "https://www.apple.com/in/store"
    const playStoreUrl = "https://play.google.com/store/games"
    const responsiveSettings = [
        {
            breakpoint: 768, // Screen width below which you want to show a single slide
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 992, // Screen width at which you want to show 2 slides
            settings: {
                slidesToShow: 2,
            },
        },
    ];
    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const showDrawMenu = () => {
        setShowMenu(true)
    }
    return (
        <>
            {/* //HEADER// */}
            <section className='' style={{ position: 'sticky', top: 0, zIndex: 99 }}>
                <Row gutter={[]} >
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                        <div className='left-header'>
                            <img style={{ width: '60px', height: '60px' }} src={logo} alt='logo' />
                            <Link to='home' className='navlink1' activeStyle={{ color: '#ECA020' }} spy={true} smooth={true} duration={100} delay={100}>
                                Home
                            </Link>
                            <div className="drwerBtn" >
                                <AiOutlineMenuUnfold onClick={() => showDrawMenu()} size={25} />
                            </div>
                        </div >
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className='right-header'>
                            <Link to='about_us' className="navlink" spy={true} activeStyle={{ color: "#000" }} smooth={true} duration={300} delay={300}>
                                About us
                            </Link>
                            <Link to='how_use' className="navlink" spy={true} activeStyle={{ color: "#000" }} smooth={true} duration={300} delay={300}>
                                How to use
                            </Link>
                            <Link to='testimonials' className="navlink" spy={true} activeStyle={{ color: "#000" }} smooth={true} duration={300} delay={300}>
                            Our testmonials
                            </Link>
                            <Button type='ghost' className='download-button' onClick={() => handleOpenModal()}  >DOWNLOAD APP</Button>
                        </div >
                    </Col>
                </Row>
            </section >


            {/* //section-1 find your purpose// */}
            < div className=''>
                <section id="home">
                    <Row gutter={[]}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className='left-side'>
                                <div className='left-side-inner'>
                                    <h1 className='heading-text'>Find your purpose</h1>
                                    <article className='article-text'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,<br /> sed diam nonumy eirmod tempor invidunt ut labore.
                                    </article>
                                    <DebounceInput className='search-tab'
                                        minLength={2}
                                        debounceTimeout={300}
                                        onChange={event => setState({ value: event.target.value })} placeholder="search"

                                    />

                                    <Button className='search-button widthProp' type="ghost" htmlType="Create button" >
                                        search
                                    </Button>

                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className='right-side'>
                            </div>
                            <img className='image-rightside' src={aaa} alt="child" />
                        </Col>
                    </Row>
                </section>
            </div >
            {/* //section-2 ABOUT US// */}
            < section id="about_us">
                <div className='background-photo'>
                    <div className='para-text'>
                        <h1 className='heading-subtext'>ABOUT US</h1>

                        <p className='article-text'>We all need encouragement and strength to not be overcome by the pressures and stresses of life. Fortunately, God has given us promises from His word to live by. We can either live by our problems or we can live the promises of God. <br /><br />As a pastor, Mark Stewart would send out by email one promise from the Bible each week for his congregation to live by. This went world-wide and is now an app for others to enjoy.</p>
                        <Button className='search-button' type="ghost" htmlType="Create button" onClick={() => handleOpenModal()}>
                            DOWNLOAD APP
                        </Button>
                    </div>
                </div>
            </section >

            {/* //section-3 DAILY PRAYERS// */}
            < section >
                <div className='card__container '>
                    <Row gutter={[12]}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="" style={{ position: 'relative' }}>
                                <img className='rounder-image' src={bbb} alt="logo" />
                                <div className="circle" />
                                <div className="circle2" />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className='right-side-text'>
                                <div className='right-side-inner'>
                                    <h1 style={{ display: 'flex' }}>DAILY PRAYERS</h1>
                                    <p className='article-text'>  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
                                    <Button className='search-button widthProp' type="ghost" htmlType="Create button" >
                                        LEARN MORE
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section >
            {/* //section-4 HOW TO USE// */}
            < section id="how_use">
                <div className='card__container '>
                    <Row gutter={[12, 12]}>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className='right-side-text'>
                                <div className='right-side-inner'>
                                    <h1>HOW TO USE</h1>
                                    <p className='article-text'>  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
                                    <Button className='search-button widthProp' type="ghost" htmlType="Create button" >
                                        LEARN MORE
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="" style={{ position: 'relative' }}>
                                <img className='rounder-image' src={ccc} alt="logo" />
                                <div className="circle" />
                                <div className="circle2" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section >
            {/* //section-5 SET REMINDER// */}
            < section >
                <div className='card__container '>
                    <Row gutter={[8, 8, 8]}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="" style={{ position: 'relative' }}>
                                <img className='rounder-image' src={ddd} alt="logo" />
                                <div className="circle" />
                                <div className="circle2" />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className='right-side-text'>
                                <div className='right-side-inner'>
                                    <h1 style={{ display: 'flex' }}>SET REMINDER</h1>
                                    <p className='article-text'>  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
                                    <Button className='search-button widthProp' type="ghost" htmlType="Create button" >
                                        LEARN MORE
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section >



            {/* //section-5 OUR TESTIMONIALS// */}
            < section id="testimonials">
                <div className='card__container' style={{ padding: 70 }}>
                    <div className="" style={{ display: "table" }}>
                        <h2 style={{ color: 'black' }}>OUR TESTIMONIALS</h2>
                        <p className='testimonialBorderDown'></p>
                    </div>

                    <Row style={{ display: "flex", justifyContent: 'center', marginTop: 50 }}>
                        <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                            <Carousel dots={false} ref={slider} arrows={false} autoplay={false} slidesToShow={3} responsive={responsiveSettings} >
                                {myBanner && myBanner?.map((item) => {
                                    return (
                                        <div className="">
                                            <Card hoverable className='cardClass'
                                                cover={<img alt="example" style={{ height: 200, width: '100%' }} src={item?.image} />}
                                            >
                                                <p className="px-5">Always relevant, thought provoking during these stressful times. Easy to access. Thankful my sis shared this app.</p>
                                                <div className="" style={{ marginTop: 80 }}>
                                                    <Rate allowHalf defaultValue={3.5} />
                                                    <h3>LisaAnn Peterson</h3>
                                                </div>
                                            </Card>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={23} sm={23} md={23} lg={23} xl={23} style={{ display: 'flex', justifyContent: 'start', marginTop: 18 }} className="mt-5 d-flex justify-content-end">
                            <div style={{ margin: '0 10px', cursor: 'pointer' }} onClick={() => slider.current.prev()}>
                                <img loading='lazy' src={prevIcon} style={{ height: 35 }} />
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div onClick={() => slider.current.next()} style={{ cursor: 'pointer' }}>
                                <img loading='lazy' src={nextIcon} style={{ height: 35 }} />
                            </div>
                        </Col>
                    </Row>

                </div>
            </section >
            {/* //section-6 FOOTER PART// */}
            <section >
                <div className=''>
                    <div className='footer-back-img'>
                        <Row gutter={[30, 10]}>
                            <Col xs={24} sm={24} md={10} lg={12} xl={12}>
                                <div className="" style={{ color: 'black' }} >
                                    <p style={{ textAlign: 'justify', color: "#0D0D0D" }}>We all need encouragement and strength to not be overcome by the pressures and stresses of life. Fortunately, God has given us promises from His word to live by. We can either live by our problems or we can live the promises of God. As a pastor, Mark Stewart would send out by email one promise from the Bible each week for his congregation to live by. This went worldwide and is now an app for others to enjoy.</p>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={14} lg={12} xl={12}>
                                <div className="getTouchParent ">
                                    <h2 style={{}}>Get In Touch</h2>
                                    <p className="black_line"></p>
                                    <div style={{ marginLeft: 12, display: 'flex' }}>
                                        <div className="emailBox" >
                                            {/* <img src={email} alt="image" /> */}
                                            <HiOutlineMail size={20} />
                                            <p className="px-2">support@encuragingwordapp.com</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <hr className="" style={{ color: 'white' }} />
                        <div className="">
                            <p className="text-center text-white m-0">Copyright © 2023 <a href="https://solidappmaker.com/" target="_blank" className="solidAppLink">solidappmaker.com</a> All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </section>
            <FloatButton.BackTop />

            {/* modal download */}
            <Modal open={openModal} centered onCancel={() => setOpenModal(false)} footer=''>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                    <h2>
                        Download Our App
                    </h2>
                    <div className="" style={{ gap: 20, display: 'flex' }}>
                        <QRCode type="canvas" value={appStoreUrl || '-'} icon={appStoreLogo} />
                        <QRCode type="canvas" value={playStoreUrl || '-'} icon={playStoreLogo} />
                    </div>
                </div>
            </Modal>

            <Drawer
                className="d-block d-lg-none"
                placement="right"
                onClose={() => setShowMenu(false)}
                open={showMenu}
                zIndex={99999}
            >
                <div className="">
                    <div className="">
                        <Link to='home'><span style={{ fontWeight: 600 }}>Home</span></Link>
                    </div>
                    <div className="mt-2">
                        <Link to='about_us'><span style={{ fontWeight: 600 }}>About Us</span></Link>
                    </div>
                    <div className="mt-2">
                        <Link to='how_use'><span style={{ fontWeight: 600 }}>How to Use</span></Link>
                    </div>
                    <div className="mt-2">
                        <Link to='testimonials'><span style={{ fontWeight: 600 }}>Testimonials</span></Link>
                    </div>
                    <div className="mt-2">
                        <Button type="ghost" style={{ backgroundColor: '#ECA020', width: '50%', marginTop: 20, color: 'white' }} className="download-button">DOWNLOAD APP</Button>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default Landing